import React from "react"
// import FLayout from "../components/flayout";
import BuyDesktop from "../../components/buyDesktop";

class BuyPage extends React.Component {
    render() {
        return (
            // <FLayout>
                <BuyDesktop location={this.props.location} seoFields={seoFields}/>
            // </FLayout>
        )
    }
}


const seoFields = {
    description: "Buy MProfit, or Renew / Upgrade your current MProfit plan. By doing so, you can immediately start aggregating & tracking your investments efficiently across multi-asset classes, manage investment-related accounting and access comprehensive reporting insights.",
    // lang,
    // meta,
    // keywords,
    title: "Buy Now",
}

export default BuyPage