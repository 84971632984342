import React from "react"
import LoginStructure from "../../shared/loginStructure";

const BuyDesktop = ({location,seoFields}) => {
  return (
    <LoginStructure
      isBuyDesktop = {true}
      nextPage={'/account/order/product'}
      showProceed={true}
      headingText={'Subscribe to MProfit'}
      submitText={'Continue'}
      passwordField={'Customer ID'}
      isTrial={true}
      trialSignUpLink={'/sign-up'}
      otherUserText={'Prefer to use your MProfit Cloud login details?'}
      location={location}
      seoFields={seoFields}
    />
  )
}

export default BuyDesktop;